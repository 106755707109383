<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Processamento Performance</h1>
      <div class="container-btns" v-if="perfil != 5 && perfil != null">
        <button class="btn btn-laranja" @click="adicionarPerformance">Processar Performance</button>
      </div>
      <form @submit.prevent class="formulario-container">
        <div class="d-md-flex">
          <div class="formulario-item">
            <label class="formulario-item-label">Origem KPI</label>
            <multiselect v-model="form.tipoKpi" label="descricao" track-by="descricao" :options="tipoKpis"
              :searchable="false" :multiple="false" placeholder="Selecione o tipo" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">CPF/CNPJ</label>
            <input type="text" v-model="form.cpfCnpj" placeholder="CPF/CNPJ" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Mês</label>
            <multiselect v-model="form.mes" label="nome" track-by="nome" :options="opcoesMeses" :searchable="false"
              :multiple="false" placeholder="Mês" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Ano</label>
            <input type="text" v-model="form.ano" placeholder="Ano" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Tipo</label>
            <multiselect label="descricao" track-by="id" v-model="form.idTipoProcessamento" :options="tipo" :searchable="false"
              :allow-empty="false" select-label="Selecionar" deselect-label="Remover"
              placeholder="Selecionar o tipo" selected-label="Selecionado" />
          </div>

        </div>
        <div class="container-btns">
          <button class="btn btn-cancelar" @click="limparFiltro">
            Limpar
          </button>
          <button class="btn btn-laranja" @click="listar(1, true)">
            Filtrar
          </button>
        </div>
      </form>

      <transition name="fade-left" mode="out-in">
        <div class="paginacao" v-if="!semDados">
          <button :disabled="paginaBusca == 1" @click="pagAnterior">Anterior</button>
          <p>{{ paginaBusca }}</p>
          <button :disabled="ultimoItem" @click="proxPagina">Próxima</button>
        </div>
      </transition>

      <div v-if="performances.length > 0" class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-cpf">
            <p>TIPO</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>DATA</p>
          </div>
          <div class="tabela-linha-nome">
            <p>CPF/CNPJ</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>KPI</p>
          </div>
          <!-- <div class="tabela-linha-nome"><p>ID ORIGEM KPI</p></div> -->
          <div class="tabela-linha-cpf">
            <p>META ALCANÇADA</p>
          </div>
          <!-- <div class="tabela-linha-nome"><p>ID META MÊS</p></div> -->
          <div class="tabela-linha-cpf">
            <p>QTD PESQUISA</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>PONTUAÇÃO PERFORMANCE</p>
          </div>
          <div class="tabela-linha-data">
            <p>MÊS VIGENTE</p>
          </div>
          <div class="tabela-linha-data">
            <p>ANO VIGENTE</p>
          </div>
          <!-- <div class="tabela-linha-btns"></div> -->
        </div>
        <div v-for="(performance, index) in performances" :key="index" class="tabela-linha tabela-linha-informacoes">
          <div class="tabela-linha-cpf">
            <p>{{ performance.tipoProcessamento }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ formatarData(performance.dataCadastro) }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ performance.cpf | formataCpfCnpj }}</p>

          </div>
          <div class="tabela-linha-cpf">
            <p>{{ performance.nomeKpi }}</p>
          </div>
          <!-- <div class="tabela-linha-nome">
            <p>{{ performance.dataCadastro }}</p>
          </div> -->
          <div class="tabela-linha-cpf">
            <p>{{ performance.metaAtingida }}</p>
          </div>
          <!-- <div class="tabela-linha-nome">
            <p>{{ performance.dataCadastro }}</p>
          </div> -->
          <div class="tabela-linha-cpf">
            <p>{{ performance.qtdPesquisa }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ performance.pontuacaoPerformance }}</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ performance.mesVigente }}</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ performance.anoVigente }}</p>
          </div>
          <!-- <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="editarPromocoes(item.id)">
              <img src="@/assets/images/icones/editar.svg" alt="Editar" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listarPerformances, comboKpis } from "@/services/apuracao-performance";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { opcoesMeses } from '@/utils/json';
import { masks } from "@/assets/js";

export default {
  name: "Performance",
  components: {
    Multiselect
  },
  data() {
    return {
      opcoesMeses: opcoesMeses.mes,
      semDados: true,
      paginaBusca: 1,
      cpf: "",
      performances: [],
      tipoKpis: [],
      tipo: [
        { id: 1, descricao: 'Fechamento' },
        { id: 2, descricao: 'Parcial' }
      ],
      form: {
        mes: "",
        tipoKpi: "",
        cpfCnpj: [],
        dataConclusao: "",
        ano: "",
        idTipoProcessamento: []
      },
      perfil: null
    };
  },
  filters: {
    formataCpfCnpj(value) {
      if (value.length > 11) {
        return masks.formataCNPJ(value);
      }
      return masks.formataCPF(value);

    }
  },
  created() {
    this.listar(1, false);
    this.listarComboKpis();
    this.perfil = localStorage.getItem('perfil');
  },

  methods: {
    listarComboKpis() {
      comboKpis().then(resp => {
        this.tipoKpis = resp.data
      })
    },
    adicionarPerformance() {
      this.$router.push("/performance/adicionar");
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    listar(pag, tipoFiltro) {
      let data = {};
      let cpfLimpo = this.form.cpfCnpj.length > 0 && this.form.cpfCnpj.replaceAll(".", "").replace("-", "");
      let cnpjLimpo = this.form.cpfCnpj.length > 0 && this.form.cpfCnpj.replaceAll(".", "").replace("/", "").replace("-", "");
      if (tipoFiltro) {
        data = {
          nomeUsuario: "",
          cpf: cpfLimpo.length <= 11 ? cpfLimpo : '',
          cnpj: cnpjLimpo.length > 13 ? cnpjLimpo : '',
          dataConclusao: this.form.dataConclusao ? moment(this.form.dataConclusao).format("YYYY-MM-DD") : "",
          dataInicio: null,
          dataFim: null,
          idTipoProcessamento: this.form.idTipoProcessamento.id && this.form.idTipoProcessamento.id,
          mesVigente: this.form.mes && this.form.mes.id ? this.form.mes.id : 0,
          anoVigente: this.form.ano ? Number(this.form.ano) : 0,
          idTipo: this.form.tipoKpi && this.form.tipoKpi.id ? this.form.tipoKpi.id : 0,
          idNome: 0,
          pagina: pag,
          qtdPorPagina: 15,
        }
      } else {
        data = {
          nomeUsuario: "",
          cpf: "",
          cnpj: "",
          dataConclusao: null,
          dataInicio: null,
          dataFim: null,
          mesVigente: 0,
          anoVigente: 0,
          idTipo: 0,
          idNome: 0,
          pagina: this.paginaBusca,
          qtdPorPagina: 15,
        }
      }

      listarPerformances(data).then(resp => {
        if (resp.data.lista.length == 0) {
          this.performances = [];
          if (pag && pag > 1) {
            this.ultimoItem = true;
          } else {
            this.semDados = true;
          }
        } else {
          resp.data.lista.length < 15 ? (this.ultimoItem = true) : (this.ultimoItem = false);
          if (pag) {
            this.paginaBusca = pag;
          }
          this.semDados = false;
          this.performances = resp.data.lista;
        }
      })
    },
    // listar() {
    //   const cpf = this.cpf.replaceAll(".", "").replace("-", "");
    //   listarPerformances(cpf).then(resp => {
    //     if(resp.data.length === 0) {
    //       this.$store.commit("SET_MODAL", {
    //         ativado: true,
    //         mensagem: "Participante sem ranking",
    //         tipo: "erro"
    //       });
    //     }
    //     this.performances = resp.data;
    //   })
    // },

    limparFiltro() {
      this.form = {
        mes: "",
        tipoKpi: {},
        cpfCnpj: [],
        dataConclusao: "",
        ano: "",
        idTipoProcessamento: []
      }
      this.performances = [];

      this.listar(1, false)
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      this.listar(pag, true);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      this.listar(pag, true);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>